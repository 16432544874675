import { useShallow } from "zustand/react/shallow";
import useStore from "../store";
import InfoToolTip from "./InfoToolTip";
import { useMemo } from "react";
import { NetworkMetadataResponse } from "../lib/api";
import { hasAtLeast, isEmpty, keys } from "remeda";

export default function SummaryMetrics({
  data,
}: {
  data: NetworkMetadataResponse;
}) {
  const filter = useStore(useShallow((state) => state.filter));
  const blockData = useStore(useShallow((state) => state.blockData));

  const fitleredBlockData = useMemo(() => {
    const filteredRollupNames = Object.values(data)
      .filter((d) => d.name !== "ethereum-mainnet")
      .filter((d) => {
        return keys(filter).every((key) => {
          if (hasAtLeast(filter[key], 1)) return filter[key].includes(d[key]);
          return true;
        });
      })
      .map((d) => d.name);

    return Object.values(blockData).filter((d) =>
      filteredRollupNames.includes(d.name),
    );
  }, [data, blockData, filter]);

  const isNotFiltered = useMemo(() => {
    return (
      (filter.stack.length == 1 && filter.stack[0] === "ethereum") ||
      keys(filter).reduce((acc, key) => acc && isEmpty(filter[key]), true)
    );
  }, [filter]);

  const unFilteredBlockData = useMemo(() => {
    const filteredRollupNames = Object.values(data)
      .filter((d) => d.name !== "ethereum-mainnet")
      .map((d) => d.name);

    return Object.values(blockData).filter((d) =>
      filteredRollupNames.includes(d.name),
    );
  }, [data, blockData]);

  const totalTps = fitleredBlockData.reduce(
    (acc, { tps }) => acc + parseFloat(tps),
    0,
  );

  const totalUnFilteredTps = unFilteredBlockData.reduce(
    (acc, { tps }) => acc + parseFloat(tps),
    0,
  );

  const shareTps = useMemo(() => {
    if (isNotFiltered) return "";

    const value = blockData?.["ethereum-mainnet"]?.tps
      ? parseFloat(blockData["ethereum-mainnet"].tps)
      : 0;

    if (value === 0) return "-";

    return ((totalTps * 100) / totalUnFilteredTps).toFixed(2) + "%";
  }, [blockData, totalTps, totalUnFilteredTps, isNotFiltered]);

  const tpsScalingFactor = useMemo(() => {
    const value = blockData?.["ethereum-mainnet"]?.tps
      ? parseFloat(blockData["ethereum-mainnet"].tps)
      : 0;

    if (value === 0) return "-";

    return (totalTps / value).toFixed(2) + "x";
  }, [blockData, totalTps]);

  const totalGps = fitleredBlockData.reduce(
    (acc, { gps }) => acc + parseFloat(gps),
    0,
  );

  const totalUnFilteredGps = unFilteredBlockData.reduce(
    (acc, { gps }) => acc + parseFloat(gps),
    0,
  );

  const gpsScalingFactor = useMemo(() => {
    const value = blockData?.["ethereum-mainnet"]?.gps
      ? parseFloat(blockData["ethereum-mainnet"].gps)
      : 0;

    if (value === 0) return "-";

    return (totalGps / value).toFixed(2) + "x";
  }, [blockData, totalGps]);

  const shareGps = useMemo(() => {
    if (isNotFiltered) return "";

    const value = blockData?.["ethereum-mainnet"]?.gps
      ? parseFloat(blockData["ethereum-mainnet"].gps)
      : 0;

    if (value === 0) return "-";

    return ((totalGps * 100) / totalUnFilteredGps).toFixed(2) + "%";
  }, [blockData, totalGps, totalUnFilteredGps, isNotFiltered]);

  const totalDps = fitleredBlockData.reduce(
    (acc, { dps }) => acc + parseFloat(dps),
    0,
  );

  const totalUnFilteredDps = unFilteredBlockData.reduce(
    (acc, { dps }) => acc + parseFloat(dps),
    0,
  );

  const dpsScalingFactor = useMemo(() => {
    const value = blockData?.["ethereum-mainnet"]?.dps
      ? parseFloat(blockData["ethereum-mainnet"].dps)
      : 0;

    if (value === 0) return "-";

    return (totalDps / value).toFixed(2) + "x";
  }, [blockData, totalDps]);

  const shareDps = useMemo(() => {
    if (isNotFiltered) return "";

    const value = blockData?.["ethereum-mainnet"]?.dps
      ? parseFloat(blockData["ethereum-mainnet"].dps)
      : 0;

    if (value === 0) return "-";

    return ((totalDps * 100) / totalUnFilteredDps).toFixed(2) + "%";
  }, [blockData, totalDps, totalUnFilteredDps, isNotFiltered]);

  return (
    <div className="tui-window tui-no-shadow">
      <fieldset className="tui-fieldset pt-[6px]">
        <legend className="center whitespace-nowrap">Totals</legend>

        <table className="w-full p-0 border-none tui-table table-fixed">
          <thead>
            <tr>
              <th>
                <InfoToolTip
                  showInfoIcon
                  toolTipText="The total transactions per second"
                >
                  <span>TPS</span>
                </InfoToolTip>
              </th>

              <th>
                <InfoToolTip
                  showInfoIcon
                  toolTipText="The total megagas (Million Gas) per second"
                >
                  <span>Mgas/s</span>
                </InfoToolTip>
              </th>

              <th>
                <InfoToolTip showInfoIcon toolTipText="Total KB per second">
                  <span>KB/s</span>
                </InfoToolTip>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="center !border-none">
                <span>{totalTps.toFixed(2)}</span>

                <div className="green-255-text">{`(${tpsScalingFactor})`}</div>
                {!!shareTps && (
                  <div className="orange-255-text">{`[${shareTps}]`}</div>
                )}
              </td>
              <td className="center !border-none">
                <span>{totalGps.toFixed(2)}</span>

                <div className="green-255-text">{`(${gpsScalingFactor})`}</div>
                {!!shareGps && (
                  <div className="orange-255-text">{`[${shareGps}]`}</div>
                )}
              </td>
              <td className="center !border-none">
                <span>{totalDps.toFixed(2)}</span>

                <div className="green-255-text">{`(${dpsScalingFactor})`}</div>
                {!!shareDps && (
                  <div className="orange-255-text">{`[${shareDps}]`}</div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </div>
  );
}
