import { Popover } from "radix-ui";
import InfoToolTip from "./InfoToolTip";
import { BlobMetadata } from "../screens/Blobs";
import { entries } from "remeda";

export default function BlobRowHeader({
  metadata,
}: {
  metadata: BlobMetadata;
}) {
  if (metadata.tooltipData) {
    return (
      <Popover.Root>
        <Popover.Trigger asChild>
          <div className="tracking-tight hover:underline hover:cursor-pointer">
            {metadata.da}
            {metadata.da === "EigenDA" ? (
              <span className="align-super text-xs ml-0.5">{"1"}</span>
            ) : (
              ""
            )}
          </div>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="tui-window w-[22rem]"
            side="bottom"
            sideOffset={16}
          >
            <fieldset className="min-w-0 tui-fieldset">
              <legend className="center">{metadata.da}</legend>

              <Popover.Close className="tui-fieldset-button">
                <span className="green-255-text">■</span>
              </Popover.Close>

              <dl>
                {entries(metadata.tooltipData).map(([key, data]) => (
                  <div className="flex justify-between gap-4" key={key}>
                    {data.tooltip ? (
                      <InfoToolTip
                        showInfoIcon={true}
                        toolTipText={data.tooltip}
                      >
                        <dt className="text-sm text-gray-200 whitespace-nowrap">
                          {key}
                        </dt>
                      </InfoToolTip>
                    ) : (
                      <dt className="text-sm text-gray-200 whitespace-nowrap">
                        {key}
                      </dt>
                    )}

                    <dd className="truncate">{data.formatted}</dd>
                  </div>
                ))}
              </dl>
            </fieldset>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    );
  }

  return <span>{metadata.da}</span>;
}
