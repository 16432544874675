import type { NetworkMetadataResponse } from "../lib/api";
import { useShallow } from "zustand/react/shallow";
import useStore from "../store";
import { chainIdToName } from "../constants/chains";
import { hasAtLeast } from "remeda";

export default function TrackerTableFilters({
  data,
}: {
  data: NetworkMetadataResponse;
}) {
  const [filterOpen, filterOpenSet] = useStore(
    useShallow((state) => [state.filterOpen, state.filterOpenSet]),
  );

  const filter = useStore(useShallow((state) => state.filter));
  const filterSet = useStore(useShallow((state) => state.filterSet));

  const stacks = new Set(Object.values(data).map((meta) => meta.stack));

  const da = new Set(Object.values(data).map((meta) => meta.da));

  const parentChain = new Set(
    Object.values(data).map((meta) => meta.parentChain),
  );

  return (
    <div className="tui-window tui-no-shadow w-full">
      <fieldset className="tui-fieldset pt-[6px]">
        <legend className="center whitespace-nowrap">Filters</legend>

        {filterOpen ? (
          <>
            <div className="grid md:grid-cols-3 gap-3">
              <div>
                <span>Stack</span>

                <div className="cyan-168">
                  {[...stacks].sort().map((stack) => (
                    <label
                      key={stack}
                      className="tui-checkbox black-255-text focus-within:!text-yellow-255"
                    >
                      {stack}
                      <input
                        type="checkbox"
                        value={stack}
                        onChange={(e) => {
                          filterSet({
                            stack: e.target.checked
                              ? [...filter.stack, stack]
                              : filter.stack.filter((s) => s !== stack),
                          });
                        }}
                        checked={filter.stack.includes(stack)}
                      />
                      <span></span>
                    </label>
                  ))}
                </div>
              </div>

              <div>
                <span>DA</span>

                <div className="cyan-168">
                  {[...da].sort().map((da) => (
                    <label
                      key={da}
                      className="tui-checkbox black-255-text focus-within:!text-yellow-255"
                    >
                      {da}
                      <input
                        type="checkbox"
                        name="da"
                        value={da}
                        onChange={(e) =>
                          e.target.checked
                            ? filterSet({ da: [...filter.da, da] })
                            : filterSet({
                                da: filter.da.filter((d) => d !== da),
                              })
                        }
                        checked={filter.da.includes(da)}
                      />
                      <span></span>
                    </label>
                  ))}
                </div>
              </div>

              <div>
                <span>Settlement</span>
                <div className="cyan-168">
                  {[...parentChain].sort().map((parentChain) => (
                    <label
                      key={parentChain}
                      className="tui-checkbox black-255-text focus-within:!text-yellow-255"
                    >
                      {
                        chainIdToName[
                          parentChain as unknown as keyof typeof chainIdToName
                        ]
                      }
                      <input
                        type="checkbox"
                        name="parentChain"
                        value={parentChain}
                        onChange={(e) =>
                          filterSet({
                            parentChain: e.target.checked
                              ? [...filter.parentChain, parentChain]
                              : filter.parentChain.filter(
                                  (p) => p !== parentChain,
                                ),
                          })
                        }
                        checked={filter.parentChain.includes(parentChain)}
                      />
                      <span></span>
                    </label>
                  ))}
                </div>
              </div>
            </div>

            {hasAtLeast(Object.values(filter).flat(), 1) && (
              <button
                onClick={() =>
                  filterSet({
                    stack: [],
                    parentChain: [],
                    da: [],
                  })
                }
                className="tui-button cyan-168 black-text mt-3 w-full tui-no-shadow"
              >
                Reset
              </button>
            )}

            <button
              className="tui-button cyan-168 w-full tui-no-shadow mt-3"
              onClick={() => filterOpenSet(false)}
            >
              Collapse
            </button>
          </>
        ) : (
          <div className="center">
            <button
              className="tui-button cyan-168 w-full tui-no-shadow"
              onClick={() => filterOpenSet(true)}
            >
              Expand
            </button>
          </div>
        )}
      </fieldset>
    </div>
  );
}
