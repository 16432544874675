import { RouterProvider, createRouter } from "@tanstack/react-router";
import React from "react";
import ReactDOM from "react-dom/client";

import "tuicss";
import "./index.css";

import { routeTree } from "./routeTree.gen";
import { ErrorBoundary } from "./components/ErrorBoundary";

const router = createRouter({
  routeTree,
  defaultErrorComponent: ({ error }) => <ErrorBoundary error={error} />,
  defaultPreload: "intent",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
