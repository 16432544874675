import { Popover } from "radix-ui";
import type { NetworkMetadata } from "../lib/api";
import InfoToolTip from "./InfoToolTip";

const G2Badge = () => {
  return (
    <svg
      className="overflow-visible h-[11px] lg:h-[14px]"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 34 14"
    >
      <path
        fill="currentColor"
        d="m8.208 3.829-1.88 3.298h3.658a2.277 2.277 0 0 0-1.998 2.284c0 .355.067.694.203.981L6.328 7.127l-1.863 3.265c.136-.287.204-.626.204-.98A2.277 2.277 0 0 0 2.67 7.126h3.658L4.448 3.83a2.244 2.244 0 0 0 1.88 1.015c.796 0 1.473-.406 1.88-1.015Zm.372 7.866c0-.356-.084-.71-.237-1.032a2.206 2.206 0 0 0 1.897 1.032c1.27 0 2.252-1.015 2.252-2.284s-.965-2.267-2.218-2.284c1.253-.017 2.218-1.015 2.218-2.283 0-1.269-.982-2.284-2.252-2.284-.813 0-1.507.406-1.914 1.049.17-.305.254-.66.254-1.049 0-1.269-.982-2.284-2.252-2.284-1.27 0-2.252 1.015-2.252 2.284 0 .39.085.744.254 1.066A2.206 2.206 0 0 0 2.416 2.56C1.146 2.56.164 3.575.164 4.844c0 1.268.965 2.266 2.218 2.283C1.13 7.144.164 8.142.164 9.411s.982 2.284 2.252 2.284c.83 0 1.49-.406 1.897-1.032a2.422 2.422 0 0 0-.237 1.032c0 1.268.982 2.283 2.252 2.283 1.27 0 2.252-1.015 2.252-2.283ZM22.918 13.641l.63-2.75 4.22-3.608c1.145-.993 2.31-2.08 2.31-3.455 0-.65-.268-.917-.86-.917-1.05 0-1.221.766-1.45 2.37h-3.055c.362-3.953 2.329-5.005 4.925-5.005 2.502 0 3.513 1.089 3.513 3.036 0 2.291-1.47 3.933-2.844 5.117L27.653 10.7h4.64l-.688 2.94h-8.687ZM17.374 13.977c-1.979 0-2.726-1.25-2.726-3.398 0-2.5 1.083-6.645 2.334-8.53C17.822.779 18.96.275 20.51.275 22.974.275 24 1.563 24 3.523c0 .542-.074 1.12-.224 1.755h-3.06c.111-.523.186-.99.186-1.363 0-.522-.168-.858-.654-.858-.522 0-.746.242-.97.69-.69 1.382-1.55 5.227-1.55 6.366 0 .821.224 1.25.878 1.25.784 0 1.138-.97 1.418-2.184H18.98l.616-2.688h4.013l-1.642 7.15h-2.427l.355-1.4c-.486.914-1.25 1.736-2.52 1.736Z"
      />
    </svg>
  );
};

export default function TrackerRowHeader({
  metadata: nm,
}: {
  metadata: NetworkMetadata;
}) {
  const g2Enabled = nm?.g2Enabled ?? false;

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <div className="whitespace-nowrap flex items-center gap-1.5 hover:underline hover:cursor-pointer">
          {g2Enabled ? <G2Badge /> : null}
          <span>{nm.label}</span>
        </div>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className="tui-window w-[22rem]"
          side="bottom"
          sideOffset={16}
        >
          <fieldset className="min-w-0 tui-fieldset">
            <legend className="center">{nm.label}</legend>

            <Popover.Close className="tui-fieldset-button">
              <span className="green-255-text">■</span>
            </Popover.Close>

            <dl>
              {nm.website && (
                <div className="flex justify-between gap-4">
                  <dt className="text-sm text-gray-200">Website</dt>

                  <dd className="truncate">
                    <a
                      className="text-sm hover:underline"
                      target="_blank"
                      rel="noopener nofollow"
                      href={nm.website}
                    >
                      {nm.website.replace(/^https?:\/\//, "")}
                    </a>
                  </dd>
                </div>
              )}

              {nm.explorer && (
                <div className="flex items-center justify-between gap-4">
                  <dt className="text-sm text-gray-200">Explorer</dt>

                  <dd className="truncate">
                    <a
                      className="text-sm hover:underline"
                      target="_blank"
                      rel="noopener nofollow"
                      href={nm.explorer}
                    >
                      {nm.explorer.replace(/^https?:\/\//, "")}
                    </a>
                  </dd>
                </div>
              )}

              <div className="flex items-center justify-between gap-4">
                <InfoToolTip
                  showInfoIcon={true}
                  toolTipText="The tech stack used by the network."
                >
                  <dt className="text-sm text-gray-200">Stack</dt>
                </InfoToolTip>

                <dd className="text-sm capitalize truncate">
                  {nm.stack ?? "N/A"}
                </dd>
              </div>

              <div className="flex items-center justify-between gap-4">
                <InfoToolTip
                  showInfoIcon={true}
                  toolTipText="Where Data Availability is located. Important for the network security."
                >
                  <dt className="text-sm text-gray-200">DA</dt>
                </InfoToolTip>

                <dd className="text-sm capitalize truncate">
                  {nm.da ?? "N/A"}
                </dd>
              </div>

              <div className="flex items-center justify-between gap-4">
                <dt className="text-sm text-gray-200">Provider</dt>

                <dd className="text-sm capitalize truncate">
                  <span>{nm?.provider ?? "N/A"}</span>
                </dd>
              </div>

              {nm.provider === "conduit" && nm.g2Enabled && (
                <div className="flex items-center justify-between gap-4">
                  <dt className="text-sm text-gray-200">Sequencer</dt>

                  <dd className="text-sm capitalize truncate">
                    <a
                      className="text-sm hover:underline"
                      href="https://www.conduit.xyz/g2-sequencer"
                      target="_blank"
                    >
                      Conduit G2
                    </a>
                  </dd>
                </div>
              )}

              {nm.superchain && (
                <div className="flex items-center justify-between gap-4">
                  <dt className="text-sm text-gray-200">Superchain</dt>

                  <InfoToolTip toolTipText="The Superchain is a collective of rollups built on the OP Stack">
                    <dd className="text-sm capitalize truncate hover:underline-offset-1">
                      {nm.superchain ? "Yes" : "No"}
                    </dd>
                  </InfoToolTip>
                </div>
              )}
            </dl>
          </fieldset>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
