import { useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useShallow } from "zustand/react/shallow";
import { chainIdToName } from "../constants/chains";
import { type LiveTrackerStat, type NetworkMetadataResponse } from "../lib/api";
import { sortNumberValue, sortStringValue } from "../lib/utils";
import useStore from "../store";
import SummaryMetrics from "./SummaryMetrics";
import TrackerRow from "./TrackerRow";
import TrackerTableColumnHeader from "./TrackerTableColumnHeader";
import TrackerTableFilters from "./TrackerTableFilters";
import { hasAtLeast, keys } from "remeda";

export type BlockData = {
  [key: string]: LiveTrackerStat;
};

export type Column = {
  header: string;
  accessorKey: string;
  live?: boolean;
  className?: string;
};

export type Sort = {
  accessorKey: string;
  order: "asc" | "desc";
  live?: boolean;
};

export default function TrackerTable({
  data,
}: {
  data: NetworkMetadataResponse | undefined;
}) {
  const [filterOpen, filterOpenSet] = useStore(
    useShallow((state) => [state.filterOpen, state.filterOpenSet]),
  );

  useHotkeys("f", () => filterOpenSet(!filterOpen), [
    filterOpenSet,
    filterOpen,
  ]);

  const blockData = useStore(useShallow((state) => state.blockData));
  const filter = useStore(useShallow((state) => state.filter));

  const [sortColumn, sortColumnSet] = useState<Sort | undefined>({
    accessorKey: "gps",
    order: "desc",
    live: true,
  });

  const mergeStaticWithLiveData = useMemo(() => {
    if (!data) {
      return [];
    }

    return Object.values(data).map((networkMetadata) => ({
      live: blockData[networkMetadata.name],
      ...networkMetadata,
    }));
  }, [data, blockData]);

  const mergedData = useMemo(() => {
    return mergeStaticWithLiveData
      .filter((d) => {
        return keys(filter).every((key) => {
          if (hasAtLeast(filter[key], 1)) filter[key].includes(d[key]);
          return true;
        });
      })
      .sort((a, b) => {
        if (!sortColumn) {
          return 0;
        }

        switch (sortColumn.accessorKey) {
          case "label":
            return sortStringValue(a?.label, b?.label, sortColumn.order);
          case "blockNumber":
            return sortNumberValue(
              a?.live?.blockNumber,
              b?.live?.blockNumber,
              sortColumn.order,
            );
          case "tps":
            return sortNumberValue(
              a?.live?.tps,
              b?.live?.tps,
              sortColumn.order,
            );
          case "gps":
            return sortNumberValue(
              a?.live?.gps,
              b?.live?.gps,
              sortColumn.order,
            );
          case "dps":
            return sortNumberValue(
              a?.live?.dps,
              b?.live?.dps,
              sortColumn.order,
            );
          case "stack":
            return sortStringValue(a?.stack, b?.stack, sortColumn.order);
          case "da":
            return sortStringValue(a?.da, b?.da, sortColumn.order);
          case "parentChain":
            return sortStringValue(
              chainIdToName[
                a?.parentChain as unknown as keyof typeof chainIdToName
              ],
              chainIdToName[
                b?.parentChain as unknown as keyof typeof chainIdToName
              ],
              sortColumn.order,
            );
          default:
            return 0;
        }
      });
  }, [filter, mergeStaticWithLiveData, sortColumn]);

  if (!data || Object.keys(data).length === 0) {
    return (
      <div className="center">
        <div className="tui-progress-bar inline-block valign-middle">
          <span className="tui-indeterminate"></span>
        </div>
      </div>
    );
  }

  const columns: Column[] = [
    {
      header: "Network",
      accessorKey: "label",
    },
    {
      header: "Block",
      accessorKey: "blockNumber",
      live: true,
    },
    {
      header: "TPS",
      accessorKey: "tps",
      live: true,
    },
    {
      header: "Mgas/s",
      accessorKey: "gps",
      live: true,
    },
    {
      header: "KB/s",
      accessorKey: "dps",
      live: true,
    },
    {
      header: "Stack",
      accessorKey: "stack",
      className: "hidden lg:table-cell",
    },
    {
      header: "DA",
      accessorKey: "da",
      className: "hidden lg:table-cell",
    },
    {
      header: "Settlement",
      accessorKey: "parentChain",
      className: "hidden lg:table-cell",
    },
  ];

  return (
    <>
      <SummaryMetrics data={data} />

      <TrackerTableFilters data={data} />

      <table className="tui-table hovered-cyan w-full">
        <TrackerTableColumnHeader
          columns={columns}
          sortColumn={sortColumn}
          sortColumnSet={sortColumnSet}
        />

        <tbody>
          {mergedData.map((data, idx) => (
            <TrackerRow key={`${data.name}-${idx}`} metadata={data} />
          ))}
        </tbody>
      </table>
    </>
  );
}
